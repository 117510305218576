import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { environment } from '@environments/environment';
import { Platform } from '@ionic/angular/standalone';
import { ICigarLogListIds } from '@models';
import debug from 'debug';
import { filter } from 'rxjs/operators';

declare var window: any;

const log = debug('cs:AnalyticsService');

enum AnalyticsEvent {
  PHOTO_SCANNER = 'photo_scanner',
  BARCODE_SCANNER = 'barcode_scanner',
  WEB = 'web',
  ADS = 'ads',
  SEARCH = 'search',
  CIGAR = 'cigar',
  REGISTER = 'sign_up',
  LOGIN = 'login',
  SOCIAL = 'social',
}
@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {
  plaform = this.platformService.is('capacitor') ? 'native' : 'web';
  device = this.platformService.is('android')
    ? 'android'
    : this.platformService.is('ios')
    ? 'ios'
    : this.platformService.is('desktop')
    ? 'desktop'
    : 'unknown';

  constructor(private router: Router, private platformService: Platform) {}
  // SOCIAL
  socialPost = () => this.trackEvent(AnalyticsEvent.SOCIAL + '_post', {});
  socialShare = () => this.trackEvent(AnalyticsEvent.SOCIAL + '_share', {});
  socialFollow = () => this.trackEvent(AnalyticsEvent.SOCIAL + '_follow', {});
  socialLike = () => this.trackEvent(AnalyticsEvent.SOCIAL + '_like', {});
  socialComment = () => this.trackEvent(AnalyticsEvent.SOCIAL + '_comment', {});
  // BARCODE_SCANNER
  barcodeScanAttempt = () =>
    this.trackEvent(AnalyticsEvent.BARCODE_SCANNER + '_attempt', {});
  barcodeScanFound = (productName: string) =>
    this.trackEvent(AnalyticsEvent.BARCODE_SCANNER + '_found', {
      event_label: productName,
    });
  barcodeScanNotFound = (barcode) =>
    this.trackEvent(AnalyticsEvent.BARCODE_SCANNER + '_not_found', {
      event_label: barcode,
    });
  barcodeScanFailed = () =>
    this.trackEvent(AnalyticsEvent.BARCODE_SCANNER + '_failed');
  // PHOTO_SCANNER
  photoScanAttempt = () =>
    this.trackEvent(AnalyticsEvent.PHOTO_SCANNER + '_attempt');
  photoScanFound = () =>
    this.trackEvent(AnalyticsEvent.PHOTO_SCANNER + '_found');
  photoScanNotFound = () =>
    this.trackEvent(AnalyticsEvent.PHOTO_SCANNER + '_not_found');
  photoScanFailed = () =>
    this.trackEvent(AnalyticsEvent.PHOTO_SCANNER + '_failed');
  // WEB
  openingMode = (label: string) =>
    this.trackEvent(AnalyticsEvent.WEB, {
      event_category: 'opening_mode',
      event_label: label,
    });
  upgradePWA = (version: string) =>
    this.trackEvent(AnalyticsEvent.WEB, {
      event_category: 'upgrade',
      event_label: version,
    });
  // ADS
  adsClick = (label: 'Banner' | 'NeptuneAds', id: number) =>
    this.trackEvent(AnalyticsEvent.ADS, {
      event_label: label,
    });
  // SEARCH
  search = (term: string) =>
    this.trackEvent(AnalyticsEvent.SEARCH, {
      event_label: term,
    });
  // CIGAR
  addTo = (list: ICigarLogListIds | 'humidor') =>
    this.trackEvent(AnalyticsEvent.CIGAR + '_add_to', {
      event_label: list,
    });
  // REGISTER
  register = () => this.trackEvent(AnalyticsEvent.REGISTER);
  // LOGIN
  login = (method: string) =>
    this.trackEvent(AnalyticsEvent.LOGIN, {
      method,
    });

  private trackEvent(eventName: string, params: Gtag.EventParams = {}) {
    if (!environment.googleTagManagerId) {
      return;
    }

    log('event', {
      eventName,
      plaform: this.plaform,
      device: this.device,
      ...params,
    });

    gtag('event', eventName, {
      plaform: this.plaform,
      device: this.device,
      ...params,
    });
  }

  async init() {
    log('init', environment.googleTagManagerId);
    if (!environment.googleTagManagerId) {
      return;
    }

    // this.gtmService.addGtmToDom();

    // this is done according to https://www.27partners.com/2016/07/using-google-analytics-in-an-ionic-app-without-a-plugin/
    // // can be used also in mobile apps
    // this.ga('create', {
    //   // Disables cookies.
    //   storage: 'none',
    //   // Your GA tracking id.
    //   trackingId: this.platform.is('capacitor')
    //     ? environment.googleAnalyticsCode
    //     : environment.googleAnalyticsCodeWeb,
    //   // Will return null if not set, and GA will then assign one.
    //   clientId: await this.localStorageService.get('ga:clientId'),
    // });

    // // Disable checkProtocolTask.
    // this.ga('set', 'checkProtocolTask', null);
    // // Set the transport url manually.
    // this.ga('set', 'transportUrl', 'https://www.google-analytics.com/collect');

    // this.ga(function (tracker) {
    //   if (!this.localStorageService.get('ga:clientId')) {
    //     // Save the assigned id for the next time the app boots.
    //     this.localStorageService.set('ga:clientId', tracker.get('clientId'));
    //   }
    // });

    // this.trackPageChanges();
  }

  // private trackPageChanges() {
  //   this.router.events
  //     .pipe(filter((event) => event instanceof NavigationEnd))
  //     .subscribe((event: any) => {
  //       // this.gtmService.pushTag({
  //       //   event: 'page',
  //       //   pageName: event.url,
  //       // });
  //     });
  // }
}

// Type definitions for non-npm package Google gtag.js API
// Project: https://developers.google.com/gtagjs
// Definitions by:  Junyoung Choi <https://github.com/rokt33r>
//                  Lucas Akira Uehara <https://github.com/KsAkira10>
// Definitions: https://github.com/DefinitelyTyped/DefinitelyTyped

declare var gtag: Gtag.Gtag;
declare namespace Gtag {
  interface Gtag {
    (
      command: 'config',
      targetId: string,
      config?: ControlParams | EventParams | ConfigParams | CustomParams
    ): void;
    (
      command: 'set',
      targetId: string,
      config: CustomParams | boolean | string
    ): void;
    (command: 'set', config: CustomParams): void;
    (command: 'js', config: Date): void;
    (
      command: 'event',
      eventName: EventNames | (string & {}),
      eventParams?: ControlParams | EventParams | CustomParams
    ): void;
    (
      command: 'get',
      targetId: string,
      fieldName: FieldNames | string,
      callback?: (field: string | CustomParams | undefined) => any
    ): void;
    (
      command: 'consent',
      consentArg: ConsentArg | string,
      consentParams: ConsentParams
    ): void;
  }

  interface ConfigParams {
    page_title?: string | undefined;
    page_location?: string | undefined;
    page_path?: string | undefined;
    send_page_view?: boolean | undefined;
  }

  interface CustomParams {
    [key: string]: any;
  }

  interface ControlParams {
    groups?: string | string[] | undefined;
    send_to?: string | string[] | undefined;
    event_callback?: (() => void) | undefined;
    event_timeout?: number | undefined;
  }

  type EventNames =
    | 'add_payment_info'
    | 'add_shipping_info'
    | 'add_to_cart'
    | 'add_to_wishlist'
    | 'begin_checkout'
    | 'checkout_progress'
    | 'earn_virtual_currency'
    | 'exception'
    | 'generate_lead'
    | 'join_group'
    | 'level_end'
    | 'level_start'
    | 'level_up'
    | 'login'
    | 'page_view'
    | 'post_score'
    | 'purchase'
    | 'refund'
    | 'remove_from_cart'
    | 'screen_view'
    | 'search'
    | 'select_content'
    | 'select_item'
    | 'select_promotion'
    | 'set_checkout_option'
    | 'share'
    | 'sign_up'
    | 'spend_virtual_currency'
    | 'tutorial_begin'
    | 'tutorial_complete'
    | 'unlock_achievement'
    | 'timing_complete'
    | 'view_cart'
    | 'view_item'
    | 'view_item_list'
    | 'view_promotion'
    | 'view_search_results';

  interface EventParams {
    checkout_option?: string | undefined;
    checkout_step?: number | undefined;
    content_id?: string | undefined;
    content_type?: string | undefined;
    coupon?: string | undefined;
    currency?: string | undefined;
    description?: string | undefined;
    fatal?: boolean | undefined;
    items?: Item[] | undefined;
    method?: string | undefined;
    number?: string | undefined;
    promotions?: Promotion[] | undefined;
    screen_name?: string | undefined;
    search_term?: string | undefined;
    shipping?: Currency | undefined;
    tax?: Currency | undefined;
    transaction_id?: string | undefined;
    value?: number | undefined;
    event_label?: string | undefined;
    event_category?: string | undefined;
  }

  type Currency = string | number;

  /**
   * Interface of an item object used in lists for this event.
   *
   * Reference:
   * @see {@link https://developers.google.com/analytics/devguides/collection/ga4/reference/events#view_item_item view_item_item}
   * @see {@link https://developers.google.com/analytics/devguides/collection/ga4/reference/events#view_item_list_item view_item_list_item}
   * @see {@link https://developers.google.com/analytics/devguides/collection/ga4/reference/events#select_item_item select_item_item}
   * @see {@link https://developers.google.com/analytics/devguides/collection/ga4/reference/events#add_to_cart_item add_to_cart_item}
   * @see {@link https://developers.google.com/analytics/devguides/collection/ga4/reference/events#view_cart_item view_cart_item}
   */
  interface Item {
    item_id?: string | undefined;
    item_name?: string | undefined;
    affiliation?: string | undefined;
    coupon?: string | undefined;
    currency?: string | undefined;
    creative_name?: string | undefined;
    creative_slot?: string | undefined;
    discount?: Currency | undefined;
    index?: number | undefined;
    item_brand?: string | undefined;
    item_category?: string | undefined;
    item_category2?: string | undefined;
    item_category3?: string | undefined;
    item_category4?: string | undefined;
    item_category5?: string | undefined;
    item_list_id?: string | undefined;
    item_list_name?: string | undefined;
    item_variant?: string | undefined;
    location_id?: string | undefined;
    price?: Currency | undefined;
    promotion_id?: string | undefined;
    promotion_name?: string | undefined;
    quantity?: number | undefined;
  }

  interface Promotion {
    creative_name?: string | undefined;
    creative_slot?: string | undefined;
    promotion_id?: string | undefined;
    promotion_name?: string | undefined;
  }

  type FieldNames = 'client_id' | 'session_id' | 'gclid';

  type ConsentArg = 'default' | 'update';
  interface ConsentParams {
    ad_storage?: 'granted' | 'denied' | undefined;
    analytics_storage?: 'granted' | 'denied' | undefined;
    wait_for_update?: number | undefined;
    region?: string[] | undefined;
  }
}
